import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Chart from 'chart.js';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.register(ChartDataLabels);

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    data = [];

    @ViewChild('shipmentStatusChartEl') private shipmentStatusChartElRef;
    shipmentStatusChart: any;

    @ViewChild('tonChartEl') private tonChartElRef;
    tonChart: any;

    @ViewChild('accidentChartEl') private accidentChartElRef;
    accidentChart: any;

    @ViewChild('agingChartEl') private agingChartElRef;
    agingChart: any;

    @ViewChild('masterCargoCreatedEl') private masterCargoCreatedElRef;
    masterCargoCreated: any;

    constructor(private route: ActivatedRoute) { }

    getColorForStatus(status) {
        status = status ? status.toUpperCase() : '';
        if (['MECHANICAL BREAKDOWN', 'ACCIDENT'].indexOf(status) != -1) {
            return ['rgba(255, 99, 132, 0.2)', 'rgb(255, 99, 132)'];
        }
        else if (['AWAITING COLLECTION'].indexOf(status) != -1) {
            return ['rgba(255, 205, 86, 0.2)', 'rgb(255, 205, 86)'];
        }
        else if (['ARRIVED', 'DELIVERED'].indexOf(status) != -1) {
            return ['rgba(75, 192, 192, 0.2)', 'rgb(75, 192, 192)'];
        } else {
            return ['rgba(54, 162, 235, 0.2)', 'rgb(54, 162, 235)'];
        }
    }

    ngOnInit() {
        this.data = this.route.snapshot.data.data;

        let shipmentStatusOptions = {
            "type": "bar",
            "data": {
                "labels": [],
                "datasets": [{
                    "data": [],
                    "backgroundColor": [],
                    "borderColor": [],
                    "borderWidth": 1
                }]
            },
            "options": {
                "legend": {
                    "display": false
                },
                "scales": {
                    "xAxes": [{
                        stacked: true,
                        display: true
                    }],
                    "yAxes": [{
                        stacked: true,
                        display: true,
                        ticks: {
                            beginAtZero: true
                        }

                    }]
                }
            }
        }
        let tonOptions = JSON.parse(JSON.stringify(shipmentStatusOptions));
        let accidentOptions = JSON.parse(JSON.stringify(shipmentStatusOptions));
        let agingOptions = JSON.parse(JSON.stringify(shipmentStatusOptions));
        let masterCargoCreatedOptions = JSON.parse(JSON.stringify(shipmentStatusOptions));

        accidentOptions['options']['events'] = ['click']
        accidentOptions['options']['onClick'] = function (event, array) {
            if (array[0]) {
                window.open("/shipments?jq=" + encodeURIComponent(JSON.stringify({
                    'had_accident_on': array[0]._view.label,
                    'is_closed': ''
                })), '_self');
            }
        }

        shipmentStatusOptions['options']['events'] = ['click']
        shipmentStatusOptions['options']['onClick'] = function (event, array) {
            if (array[0]) {
                window.open("/shipments?jq=" + encodeURIComponent(JSON.stringify({
                    'current_status': array[0]._view.label
                })), '_self');
            }
        }

        for (let d of this.data['status']) {
            shipmentStatusOptions["data"]["labels"].push(d.label)
            shipmentStatusOptions["data"]["datasets"][0]["data"].push(parseInt(d.value.split("(")[0]))

            let colors = this.getColorForStatus(d.label)
            shipmentStatusOptions["data"]["datasets"][0]["backgroundColor"].push(colors[0]);
            shipmentStatusOptions["data"]["datasets"][0]["borderColor"].push(colors[1]);

            tonOptions["data"]["labels"].push(d.label)
            tonOptions["data"]["datasets"][0]["data"].push(parseFloat(d.value.split("(")[1].split(" ")[0]))
            tonOptions["data"]["datasets"][0]["backgroundColor"].push(colors[0]);
            tonOptions["data"]["datasets"][0]["borderColor"].push(colors[1]);

        }

        for (let d of this.data['created_in_report']) {
            masterCargoCreatedOptions["data"]["labels"].push(d.label)
            masterCargoCreatedOptions["data"]["datasets"][0]["data"].push(d.value)
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);

            masterCargoCreatedOptions["data"]["datasets"][0]["backgroundColor"].push("rgba(" + r + "," + g + "," + b + ", 0.2)");
            masterCargoCreatedOptions["data"]["datasets"][0]["borderColor"].push("rgb(" + r + "," + g + "," + b + ")");
        }

        accidentOptions['options']['scales']['yAxes'][0]['ticks']['stepSize'] = 1;
        for (let d of this.data['accident_report']) {
            accidentOptions["data"]["labels"].push(d.label)
            accidentOptions["data"]["datasets"][0]["data"].push(d.value)
        }

        for (let d of this.data['aging_report']) {
            agingOptions["data"]["labels"].push(d.value)
            agingOptions["data"]["datasets"][0]["data"].push(d.label)

            let bgCol = 'rgba(255, 99, 132, 0.2)';
            let boCol = 'rgb(255, 99, 132)';

            if (d.value == '0-2') {
                bgCol = 'rgba(75, 192, 192, 0.2)';
                boCol = 'rgb(75, 192, 192)';
            }
            if (d.value == '3-7') {
                bgCol = 'rgba(255, 205, 86, 0.2)';
                boCol = 'rgb(255, 205, 86)';
            }
            agingOptions["data"]["datasets"][0]["backgroundColor"].push(bgCol);
            agingOptions["data"]["datasets"][0]["borderColor"].push(boCol);
        }

        this.tonChart = new Chart(this.tonChartElRef.nativeElement, tonOptions);
        this.agingChart = new Chart(this.agingChartElRef.nativeElement, agingOptions);
        this.accidentChart = new Chart(this.accidentChartElRef.nativeElement, accidentOptions);
        this.masterCargoCreated = new Chart(this.masterCargoCreatedElRef.nativeElement, masterCargoCreatedOptions);
        this.shipmentStatusChart = new Chart(this.shipmentStatusChartElRef.nativeElement, shipmentStatusOptions);
    }


    stringifyParams(params) {
        return {
            jq: JSON.stringify(params)
        }
    }
}
