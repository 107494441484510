import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    returnUrl: string;
    data: any = {};
    errors: any = {};
    logoPath='';

    constructor(
        // private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService
    ) { 
        this.logoPath = 'https://fuata-services.herokuapp.com/static/logos/' + window.location.hostname + '.png';

    }

    ngOnInit() {
        /* this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        }); */

        // reset login status
        this.renderer.addClass(document.body, 'login');

        this.authenticationService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'login');
    }

    // convenience getter for easy access to form fields
    // get f() { return this.loginForm.controls; }

    onSubmit() {
        this.authenticationService.login(this.data['username'], this.data['password'], window.location.hostname).subscribe(res => {
            this.router.navigate([this.returnUrl]);
        }, error => {
            this.errors = error.error;
            // this.loading = false;
        });
    }

}
