import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, AdminGuard, ManagerOrAdmin } from './guards';

import { IndexRedirector } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';

const routes: Routes = [{
    path: '',
    pathMatch: 'full',
    component: IndexRedirector
}, {
    path: 'logout',
    pathMatch: 'full',
    redirectTo: 'login'
}, {
    path: '',
    canActivate: [ AuthGuard ],
    children: [{
        path: 'shipments',
        loadChildren: './shipment/shipment.module#ShipmentModule',
    }, {
        path: 'preloads',
        loadChildren: './preload/preload.module#PreloadModule',
    }, {
        path: 'cargos',
        loadChildren: './cargo/cargo.module#CargoModule',
        canActivate: [ManagerOrAdmin]
    }, {
        path: 'reports',
        loadChildren: './report/report.module#ReportModule',
        canActivate: [ManagerOrAdmin]
    }, {
        path: 'transporters',
        loadChildren: './transporter/transporter.module#TransporterModule',
        canActivate: [AdminGuard]
    }, {
        path: 'manufacturers',
        loadChildren: './manufacturer/manufacturer.module#ManufacturerModule',
        canActivate: [AdminGuard]
    }, {
        path: 'consignees',
        loadChildren: './consignee/consignee.module#ConsigneeModule',
        canActivate: [AdminGuard]
    },{
        path: 'transporters-mgmt',
        loadChildren: './transporter-mgmt/transporter-mgmt.module#TransporterMgmtModule',
        canActivate: [AdminGuard]
    },{
        path: 'users',
        loadChildren: './user/user.module#UserModule',
        canActivate: [AdminGuard]
    }]
}, {
    path: 'login',
    component: LoginComponent
}, {
    path: 'dashboard',
    component: DashboardComponent,
    resolve: { data: DashboardResolver },
    canActivate: [ManagerOrAdmin]
}, {
    path: '**',
    redirectTo: '',
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
