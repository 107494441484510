import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({providedIn: 'root'})
export class APIService {
    constructor(private http: HttpClient) { }

    get(endpoint, query_params?) {
        return this.http.get<[]>(`${environment.apiUrl}${endpoint}`, query_params ? { params: query_params } : {});
    }
    getPaginated(endpoint, query_params?) {
        return this.http.get(`${environment.apiUrl}${endpoint}`, query_params ? { params: query_params } : {});
    }
    delete(endpoint) {
        return this.http.delete<[]>(`${environment.apiUrl}${endpoint}`);
    }
    post(endpoint, data) {
        return this.http.post(`${environment.apiUrl}${endpoint}`, data);
    }
    patch(endpoint, data) {
        return this.http.patch(`${environment.apiUrl}${endpoint}`, data);
    }
}
