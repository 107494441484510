// https://github.com/cornflourblue/angular-7-jwt-authentication-example
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartModule } from 'angular2-chartjs';

import { AppComponent, IndexRedirector } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './helpers';

import { DashboardResolver } from './dashboard/dashboard.resolver';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AllShipmentsComponent } from './all-shipments/all-shipments.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        IndexRedirector,
        DashboardComponent,
        AllShipmentsComponent,
    ],
    imports: [
        ChartModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        // SharedModule,
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
    }, {
        provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    }, DashboardResolver, ],
    bootstrap: [AppComponent]
})
export class AppModule { }
