import { Component } from '@angular/core';

import { AuthenticationService } from './services';
import { User } from './models';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'FUATA-UI';
    logoPath = '';
    currentUser: User;
    loading = true;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.logoPath = 'https://fuata-services.herokuapp.com/static/logos/' + window.location.hostname + '.png';
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        })
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true
        }
        if (event instanceof NavigationEnd) {
            this.loading = false
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false
        }
        if (event instanceof NavigationError) {
            this.loading = false
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

}

@Component({
    selector: 'app-index-redirector',
    template: ''
})
export class IndexRedirector {

    constructor(private router: Router,
                private authenticationService: AuthenticationService) {

        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser && currentUser['user'] && (currentUser['user']['kind'] == 'owner' || currentUser['user']['kind'] == 'administrator' || currentUser['user']['kind'] == 'manager')) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/shipments']);
        }

    }
}
