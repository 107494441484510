import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { APIService } from '../services';

@Injectable()
export class DashboardResolver implements Resolve<any> {
    constructor(private apiService: APIService) {}

    resolve() {
        return this.apiService.get('dashboard');
    }
}
